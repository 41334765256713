<template>
  <d-container fluid>
    <pageTitle title="Modellen" :back="true"/>
    <d-row>
      <vCard :col="8" header="Model" :actions="actions">
        <editModel :model-id="$route.params.model_id"
                   :make-id="$route.params.make_id"
                   :disabled="disabled" @submit="eventEditModel"/>
      </vCard>
    </d-row>
  </d-container>
</template>

<script>
export default {
  name: 'Overzicht',
  title: 'Model',
  components: {
    editModel: () => import('@/components/forms/model/editModel')
  },
  computed: {
    actions: {
      get() {
        let icon = this.disabled ? 'edit' : 'clear'
        return [{
          icon: `<i class="material-icons">${icon}</i>`,
          fn: () => {
            this.disabled = !this.disabled
          }
        }]
      }
    }
  },
  data() {
    return {
      data: null,
      disabled: true
    }
  },
  methods: {
    eventEditModel() {
      console.log('data')
    }
  }
}
</script>
